// extracted by mini-css-extract-plugin
export var alt = "ManageReservation-module--alt--9f186";
export var container = "ManageReservation-module--container--9be68";
export var desktopOnly = "ManageReservation-module--desktopOnly--49ff0";
export var main = "ManageReservation-module--main--78a33";
export var mobileOnly = "ManageReservation-module--mobileOnly--a6f2f";
export var styledContainer = "ManageReservation-module--styledContainer--436f5";
export var styledLink = "ManageReservation-module--styledLink--0ecef";
export var textLeft = "ManageReservation-module--textLeft--99539";
export var title1 = "ManageReservation-module--title1--f1a52";
export var visuallyHidden = "ManageReservation-module--visuallyHidden--de805";