import { navigate } from "gatsby";
import moment from "moment-mini";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Controller, useForm } from "react-hook-form";
import { Constants } from "../@types/Constants";
import ErrorMessageAlert from "../components/common/ErrorMessageAlert/ErrorMessageAlert";
import AlertMessage from "../components/common/ErrorMessageAlert/ManageReservationAlert/AlertMessage";
import Layout from "../components/global/Layout/Layout";
import SEO from "../components/global/SEO/SEO";
import {
  StyledContainer,
  StyledLink,
} from "../pageHelpers/ManageReservation/ManageReservationHelpers";
import { fetchReservationDetails } from "../services/crs";
import { resParseDateFormat } from "../services/dates";
import { Storage } from "../utils/storage";

const ManageReservation: React.FC = () => {
  const { handleSubmit, errors, control } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
  });
  const required = "This field is required.";
  const [validated, _setValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMetaData, setErrorMetaData] = useState("");
  const onSubmit = async (data: any, event: any) => {
    event.preventDefault();
    setErrorMessage("");
    setIsSubmitting(true);

    const reservationId = data.confirmationNo.toLowerCase();
    const length = reservationId.length;

    if (length > 13) {
      setErrorMessage(
        `The Confirmation Number you entered has too many characters. Please check the ID and try again.`
      );
      setErrorMetaData(data.confirmationNo);
      setIsSubmitting(false);
      return;
    } else if (length < 8) {
      setErrorMessage(
        `The Confirmation Number you entered has too few characters. Please check the ID and try again.`
      );
      setErrorMetaData(data.confirmationNo);
      setIsSubmitting(false);
      return;
    } else {
      if (length > 7 && length < 11 && !isNaN(reservationId)) {
        setErrorMessage(
          `You entered an invalid Confirmation Number. Reservations placed on online travel agent websites must be managed on those sites at this time.`
        );
        setErrorMetaData(data.confirmationNo);
        setIsSubmitting(false);
        return;
      } else if (
        !(
          reservationId.includes("se") ||
          reservationId.includes("ck") ||
          reservationId.includes("ic")
        )
      ) {
        setErrorMessage(
          `You entered an invalid Confirmation Number. Please check the ID and and try again.`
        );
        setErrorMetaData(data.confirmationNo);
        setIsSubmitting(false);
        return;
      } else {
        const response = await fetchReservationDetails({
          resId: data.confirmationNo,
          lastName: data.lastName,
        });
        if (!response || response.error) {
          setErrorMessage(response.errorSummary);
          setErrorMetaData(data.confirmationNo);
        } else {
          const reservation = response.value;
          const startDate =
            reservation.roomRates[0].effectiveDate.split(" ")[0];
          const endDate = reservation.roomRates[0].expireDate.split(" ")[0];
          navigate("/reservation", {
            state: {
              reservation: {
                crs_reservation_id: data.confirmationNo,
                arrival: moment(startDate, "MM-DD-YYYY").format(
                  resParseDateFormat
                ),
                departure: moment(endDate, "MM-DD-YYYY").format(
                  resParseDateFormat
                ),
                details: reservation,
              },
              lastName: data.lastName,
            },
          });
        }
      }
    }

    setIsSubmitting(false);
  };

  React.useEffect(() => {
    Storage.ClearLocalStorageValue(Constants.BRAND_BIAS_KEY);
  }, []);

  return (
    <Layout reservation>
      <SEO title={`Manage Reservation`} />
      <StyledContainer>
        <div className="d-flex justify-content-center">
          <Row className="justify-content-md-center">
            <Col className="justify-content-md-center">
              <p className="text-muted my-2">
                <small>RESERVATION LOOKUP</small>
              </p>
              <h1 id="find-your-reservation">Find Your Reservation</h1>
              <p>
                Look up your reservation to view, modify, or cancel a
                reservation at any Sonesta hotel.
              </p>
              {errorMessage && (
                <ErrorMessageAlert
                  errorType={Constants.ERRORSTYPE.RESERVATION}
                  errorSubType={Constants.ERRORSSUBTYPE.RESRVATION.FETCH}
                  message={`${errorMessage} -- ${errorMetaData}`}
                >
                  <AlertMessage
                    id={"manage-reservation-error"}
                    errorMessage={errorMessage}
                  />
                </ErrorMessageAlert>
              )}
              <Form
                noValidate
                validated={validated}
                onSubmit={handleSubmit(onSubmit)}
              >
                <Col>
                  <Form.Group as={Col} controlId="confirmationNo">
                    <Form.Label>
                      Confirmation Number<span className="text-danger">*</span>
                    </Form.Label>
                    <Controller
                      as={
                        <Form.Control
                          isInvalid={errors.confirmationNo}
                          autoComplete="on"
                        />
                      }
                      type="text"
                      name="confirmationNo"
                      control={control}
                      rules={{
                        required: required,
                      }}
                      defaultValue=""
                    />
                    <Form.Control.Feedback
                      type="invalid"
                      id="reservation-id-error-message"
                    >
                      {errors.confirmationNo && errors.confirmationNo.message}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} controlId="lastName" className="mt-2">
                    <Form.Label>
                      Last Name<span className="text-danger">*</span>
                    </Form.Label>
                    <Controller
                      as={
                        <Form.Control
                          autoComplete="family-name"
                          isInvalid={errors.lastName}
                        />
                      }
                      type="text"
                      name="lastName"
                      control={control}
                      rules={{
                        required: required,
                      }}
                      defaultValue=""
                    />
                    <Form.Control.Feedback
                      type="invalid"
                      id="last-name-error-message"
                    >
                      {errors.lastName && errors.lastName.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <div className="d-flex justify-content-center ">
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    className="mt-5 p-2 px-4"
                    size="sm"
                    id={"find-reservation"}
                  >
                    {isSubmitting ? `Please wait...` : `Find Reservation`}
                  </Button>
                </div>
                <div className="d-flex justify-content-center mt-4">
                  <p>
                    Already a member?{" "}
                    <StyledLink to={"/login"} id="login-for-reservation">
                      Sign In
                    </StyledLink>
                  </p>
                </div>
              </Form>
            </Col>
          </Row>
        </div>
      </StyledContainer>
    </Layout>
  );
};
export default ManageReservation;
