import React from "react";
import Alert from "react-bootstrap/Alert";
import { alertMessage } from "./AlertMessage.module.scss";

export const AlertMessage: React.FC<any> = (props) => {
  return (
    <div>
      <Alert className={alertMessage} id={props.id} variant={`danger`}>
        {" "}
        {props.errorMessage}
      </Alert>
    </div>
  );
};
export default AlertMessage;
